export default class AuthStateService {
    // Services
    $location = null;
    $window = null;

    // Local Attributes
    app = null;
    credentials = {};
    legacyFeedsupportRedirect = false;
    twoFactorTokens = [];
    session = null;
    view = 'login';
    redirect = null;
    referrer = null;

    constructor($location, $window) {
        this.$location = $location;
        this.$window = $window;
    }

    setApp(app) {
        this.app = app;
    }

    getApp() {
        return this.app;
    }

    setCredentials(username, password) {
        this.credentials = {
            'username': username,
            'password': password
        };
    }

    getCredentials() {
        return this.credentials;
    }

    setLegacyFeedsupportRedirect(legacyFeedsupportRedirect) {
        this.legacyFeedsupportRedirect = legacyFeedsupportRedirect;
    }

    getLegacyFeedsupportRedirect() {
        return this.legacyFeedsupportRedirect;
    }

    setTwoFactorTokens(tokens) {
        this.twoFactorTokens = tokens;
    }

    getTwoFactorTokens() {
        return this.twoFactorTokens;
    }

    setRememberMeToken(token) {
        this.$window.localStorage.setItem('token', token);
    }

    getRememberMeToken() {
        return this.$window.localStorage.getItem('token');
    }

    setSession(session) {
        this.session = session;
    }

    getSession() {
        return this.session;
    }

    setView(view) {
        this.view = view;
    }

    getView() {
        return this.view;
    }

    setRedirect(redirect) {
        this.redirect = redirect;
    }

    getRedirect() {
        return this.redirect;
    }

    setReferrer(referrer) {
        this.referrer = referrer;
    }

    getReferrer() {
        return this.referrer;
    }

    redirectUser() {
        let params = {};

        if (this.app) {
            params['app'] = this.app;
        }

        if (this.redirect) {
            params['redirect'] = this.redirect;
        }

        this.$location.path('/redirect').search(params);
    }
}

AuthStateService.$inject = ['$location', '$window'];