export default class FdxTwoFactorFormController {
    // Services
    AuthDataService = null;
    AuthStateService = null;

    // Local Attributes
    loading = false;
    submissionError = false;
    token = null;
    rememberThisComputer = false;

    constructor(AuthDataService, AuthStateService) {
        this.AuthDataService = AuthDataService;
        this.AuthStateService = AuthStateService;
    }

    submit() {
        this.loading = true;

        const credentials = this.AuthStateService.getCredentials();

		const promise = this.AuthDataService.loginWithToken(
            credentials.username, 
            credentials.password,
            this.token,
            this.rememberThisComputer
        );

        promise.then(
            (response) => {
                if (response.data.remember_me_token) {
                    this.AuthStateService.setRememberMeToken(response.data.remember_me_token);
                }

                this.AuthStateService.setTwoFactorTokens(response.data.tokens);

                if (response.data.password_needs_reset) {
                    this.AuthStateService.setView('reset');
                } else {
                    this.AuthStateService.redirectUser();
                }
            },
            (response) => {
                this.submissionError = response.message;
            }
        ).finally(() => {
            this.loading = false;
        });
    }
}

FdxTwoFactorFormController.$inject = ['AuthDataService', 'AuthStateService'];