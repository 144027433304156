import './accept_invite.styles.scss';

feedAuthApp.controller('AcceptInviteController', ['$scope', '$http','$location', 'fdxUtils', function($scope, $http, $location, fdxUtils) {
	$scope.data = {
		'country': 'United States',
		'countries': [
			{'key': 'Afghanistan', 'value': 'Afghanistan'},
			{'key': 'Aland Islands', 'value': 'Aland Islands'},
			{'key': 'Albania', 'value': 'Albania'},
			{'key': 'Algeria', 'value': 'Algeria'},
			{'key': 'American Samoa', 'value': 'American Samoa'},
			{'key': 'Andorra', 'value': 'Andorra'},
			{'key': 'Angola', 'value': 'Angola'},
			{'key': 'Anguilla', 'value': 'Anguilla'},
			{'key': 'Antarctica', 'value': 'Antarctica'},
			{'key': 'Antigua and Barbuda', 'value': 'Antigua and Barbuda'},
			{'key': 'Argentina', 'value': 'Argentina'},
			{'key': 'Armenia', 'value': 'Armenia'},
			{'key': 'Aruba', 'value': 'Aruba'},
			{'key': 'Australia', 'value': 'Australia'},
			{'key': 'Austria', 'value': 'Austria'},
			{'key': 'Azerbaijan', 'value': 'Azerbaijan'},
			{'key': 'Bahamas', 'value': 'Bahamas'},
			{'key': 'Bahrain', 'value': 'Bahrain'},
			{'key': 'Bangladesh', 'value': 'Bangladesh'},
			{'key': 'Barbados', 'value': 'Barbados'},
			{'key': 'Belarus', 'value': 'Belarus'},
			{'key': 'Belgium', 'value': 'Belgium'},
			{'key': 'Belize', 'value': 'Belize'},
			{'key': 'Benin', 'value': 'Benin'},
			{'key': 'Bermuda', 'value': 'Bermuda'},
			{'key': 'Bhutan', 'value': 'Bhutan'},
			{'key': 'Bolivia', 'value': 'Bolivia'},
			{'key': 'Bosnia and Herzegovina', 'value': 'Bosnia and Herzegovina'},
			{'key': 'Botswana', 'value': 'Botswana'},
			{'key': 'Bouvet Island', 'value': 'Bouvet Island'},
			{'key': 'Brazil', 'value': 'Brazil'},
			{'key': 'British Indian Ocean Territory', 'value': 'British Indian Ocean Territory'},
			{'key': 'Brunei Darussalam', 'value': 'Brunei Darussalam'},
			{'key': 'Bulgaria', 'value': 'Bulgaria'},
			{'key': 'Burkina Faso', 'value': 'Burkina Faso'},
			{'key': 'Burundi', 'value': 'Burundi'},
			{'key': 'Cambodia', 'value': 'Cambodia'},
			{'key': 'Cameroon', 'value': 'Cameroon'},
			{'key': 'Canada', 'value': 'Canada'},
			{'key': 'Cape Verde', 'value': 'Cape Verde'},
			{'key': 'Cayman Islands', 'value': 'Cayman Islands'},
			{'key': 'Central African Republic', 'value': 'Central African Republic'},
			{'key': 'Chad', 'value': 'Chad'},
			{'key': 'Chile', 'value': 'Chile'},
			{'key': 'China', 'value': 'China'},
			{'key': 'Christmas Island', 'value': 'Christmas Island'},
			{'key': 'Cocos Islands', 'value': 'Cocos Islands'},
			{'key': 'Colombia', 'value': 'Colombia'},
			{'key': 'Comoros', 'value': 'Comoros'},
			{'key': 'Congo', 'value': 'Congo'},
			{'key': 'Cook Islands', 'value': 'Cook Islands'},
			{'key': 'Costa Rica', 'value': 'Costa Rica'},
			{'key': 'Cote D Ivoire', 'value': 'Cote D Ivoire'},
			{'key': 'Croatia', 'value': 'Croatia'},
			{'key': 'Cuba', 'value': 'Cuba'},
			{'key': 'Cyprus', 'value': 'Cyprus'},
			{'key': 'Czech Republic', 'value': 'Czech Republic'},
			{'key': 'Denmark', 'value': 'Denmark'},
			{'key': 'Djibouti', 'value': 'Djibouti'},
			{'key': 'Dominica', 'value': 'Dominica'},
			{'key': 'Dominican Republic', 'value': 'Dominican Republic'},
			{'key': 'Ecuador', 'value': 'Ecuador'},
			{'key': 'Egypt', 'value': 'Egypt'},
			{'key': 'El Salvador', 'value': 'El Salvador'},
			{'key': 'Equatorial Guinea', 'value': 'Equatorial Guinea'},
			{'key': 'Eritrea', 'value': 'Eritrea'},
			{'key': 'Estonia', 'value': 'Estonia'},
			{'key': 'Ethiopia', 'value': 'Ethiopia'},
			{'key': 'Europe', 'value': 'Europe'},
			{'key': 'Falkland Islands', 'value': 'Falkland Islands'},
			{'key': 'Faroe Islands', 'value': 'Faroe Islands'},
			{'key': 'Fiji', 'value': 'Fiji'},
			{'key': 'Finland', 'value': 'Finland'},
			{'key': 'France', 'value': 'France'},
			{'key': 'French Guiana', 'value': 'French Guiana'},
			{'key': 'French Polynesia', 'value': 'French Polynesia'},
			{'key': 'French Southern Territories', 'value': 'French Southern Territories'},
			{'key': 'Gabon', 'value': 'Gabon'},
			{'key': 'Gambia', 'value': 'Gambia'},
			{'key': 'Georgia', 'value': 'Georgia'},
			{'key': 'Germany', 'value': 'Germany'},
			{'key': 'Ghana', 'value': 'Ghana'},
			{'key': 'Gibraltar', 'value': 'Gibraltar'},
			{'key': 'Greece', 'value': 'Greece'},
			{'key': 'Greenland', 'value': 'Greenland'},
			{'key': 'Grenada', 'value': 'Grenada'},
			{'key': 'Guadeloupe', 'value': 'Guadeloupe'},
			{'key': 'Guam', 'value': 'Guam'},
			{'key': 'Guatemala', 'value': 'Guatemala'},
			{'key': 'Guernsey', 'value': 'Guernsey'},
			{'key': 'Guinea', 'value': 'Guinea'},
			{'key': 'Guyana', 'value': 'Guyana'},
			{'key': 'Haiti', 'value': 'Haiti'},
			{'key': 'Heard Island and McDonald Islands', 'value': 'Heard Island and McDonald Islands'},
			{'key': 'Holy See', 'value': 'Holy See'},
			{'key': 'Honduras', 'value': 'Honduras'},
			{'key': 'Hong Kong', 'value': 'Hong Kong'},
			{'key': 'Hungary', 'value': 'Hungary'},
			{'key': 'Iceland', 'value': 'Iceland'},
			{'key': 'India', 'value': 'India'},
			{'key': 'Indonesia', 'value': 'Indonesia'},
			{'key': 'Iran', 'value': 'Iran'},
			{'key': 'Iraq', 'value': 'Iraq'},
			{'key': 'Ireland', 'value': 'Ireland'},
			{'key': 'Isle of Man', 'value': 'Isle of Man'},
			{'key': 'Israel', 'value': 'Israel'},
			{'key': 'Italy', 'value': 'Italy'},
			{'key': 'Jamaica', 'value': 'Jamaica'},
			{'key': 'Japan', 'value': 'Japan'},
			{'key': 'Jersey', 'value': 'Jersey'},
			{'key': 'Jordan', 'value': 'Jordan'},
			{'key': 'Kazakhstan', 'value': 'Kazakhstan'},
			{'key': 'Kenya', 'value': 'Kenya'},
			{'key': 'Kiribati', 'value': 'Kiribati'},
			{'key': 'Korea', 'value': 'Korea'},
			{'key': 'Kuwait', 'value': 'Kuwait'},
			{'key': 'Kyrgyzstan', 'value': 'Kyrgyzstan'},
			{'key': 'Lao Peoples Democratic Republic', 'value': 'Lao Peoples Democratic Republic'},
			{'key': 'Latvia', 'value': 'Latvia'},
			{'key': 'Lebanon', 'value': 'Lebanon'},
			{'key': 'Lesotho', 'value': 'Lesotho'},
			{'key': 'Liberia', 'value': 'Liberia'},
			{'key': 'Libya', 'value': 'Libya'},
			{'key': 'Liechtenstein', 'value': 'Liechtenstein'},
			{'key': 'Lithuania', 'value': 'Lithuania'},
			{'key': 'Luxembourg', 'value': 'Luxembourg'},
			{'key': 'Macau', 'value': 'Macau'},
			{'key': 'Macedonia', 'value': 'Macedonia'},
			{'key': 'Madagascar', 'value': 'Madagascar'},
			{'key': 'Malawi', 'value': 'Malawi'},
			{'key': 'Malaysia', 'value': 'Malaysia'},
			{'key': 'Maldives', 'value': 'Maldives'},
			{'key': 'Mali', 'value': 'Mali'},
			{'key': 'Malta', 'value': 'Malta'},
			{'key': 'Marshall Islands', 'value': 'Marshall Islands'},
			{'key': 'Martinique', 'value': 'Martinique'},
			{'key': 'Mauritania', 'value': 'Mauritania'},
			{'key': 'Mauritius', 'value': 'Mauritius'},
			{'key': 'Mayotte', 'value': 'Mayotte'},
			{'key': 'Mexico', 'value': 'Mexico'},
			{'key': 'Micronesia', 'value': 'Micronesia'},
			{'key': 'Moldova', 'value': 'Moldova'},
			{'key': 'Monaco', 'value': 'Monaco'},
			{'key': 'Mongolia', 'value': 'Mongolia'},
			{'key': 'Montenegro', 'value': 'Montenegro'},
			{'key': 'Montserrat', 'value': 'Montserrat'},
			{'key': 'Morocco', 'value': 'Morocco'},
			{'key': 'Mozambique', 'value': 'Mozambique'},
			{'key': 'Myanmar', 'value': 'Myanmar'},
			{'key': 'Namibia', 'value': 'Namibia'},
			{'key': 'Nauru', 'value': 'Nauru'},
			{'key': 'Nepal', 'value': 'Nepal'},
			{'key': 'Netherlands', 'value': 'Netherlands'},
			{'key': 'Netherlands Antilles', 'value': 'Netherlands Antilles'},
			{'key': 'New Caledonia', 'value': 'New Caledonia'},
			{'key': 'New Zealand', 'value': 'New Zealand'},
			{'key': 'Nicaragua', 'value': 'Nicaragua'},
			{'key': 'Niger', 'value': 'Niger'},
			{'key': 'Nigeria', 'value': 'Nigeria'},
			{'key': 'Niue', 'value': 'Niue'},
			{'key': 'Norfolk Island', 'value': 'Norfolk Island'},
			{'key': 'Northern Mariana Islands', 'value': 'Northern Mariana Islands'},
			{'key': 'Norway', 'value': 'Norway'},
			{'key': 'Oman', 'value': 'Oman'},
			{'key': 'Pakistan', 'value': 'Pakistan'},
			{'key': 'Palau', 'value': 'Palau'},
			{'key': 'Palestinian Territory', 'value': 'Palestinian Territory'},
			{'key': 'Panama', 'value': 'Panama'},
			{'key': 'Papua New Guinea', 'value': 'Papua New Guinea'},
			{'key': 'Paraguay', 'value': 'Paraguay'},
			{'key': 'Peru', 'value': 'Peru'},
			{'key': 'Philippines', 'value': 'Philippines'},
			{'key': 'Pitcairn Islands', 'value': 'Pitcairn Islands'},
			{'key': 'Poland', 'value': 'Poland'},
			{'key': 'Portugal', 'value': 'Portugal'},
			{'key': 'Puerto Rico', 'value': 'Puerto Rico'},
			{'key': 'Qatar', 'value': 'Qatar'},
			{'key': 'Reunion', 'value': 'Reunion'},
			{'key': 'Romania', 'value': 'Romania'},
			{'key': 'Russian Federation', 'value': 'Russian Federation'},
			{'key': 'Rwanda', 'value': 'Rwanda'},
			{'key': 'Saint Barthelemy', 'value': 'Saint Barthelemy'},
			{'key': 'Saint Helena', 'value': 'Saint Helena'},
			{'key': 'Saint Kitts and Nevis', 'value': 'Saint Kitts and Nevis'},
			{'key': 'Saint Lucia', 'value': 'Saint Lucia'},
			{'key': 'Saint Martin', 'value': 'Saint Martin'},
			{'key': 'Saint Pierre and Miquelon', 'value': 'Saint Pierre and Miquelon'},
			{'key': 'Saint Vincent', 'value': 'Saint Vincent'},
			{'key': 'Samoa', 'value': 'Samoa'},
			{'key': 'San Marino', 'value': 'San Marino'},
			{'key': 'Sao Tome and Principe', 'value': 'Sao Tome and Principe'},
			{'key': 'Saudi Arabia', 'value': 'Saudi Arabia'},
			{'key': 'Senegal', 'value': 'Senegal'},
			{'key': 'Serbia', 'value': 'Serbia'},
			{'key': 'Seychelles', 'value': 'Seychelles'},
			{'key': 'Sierra Leone', 'value': 'Sierra Leone'},
			{'key': 'Singapore', 'value': 'Singapore'},
			{'key': 'Slovakia', 'value': 'Slovakia'},
			{'key': 'Slovenia', 'value': 'Slovenia'},
			{'key': 'Solomon Islands', 'value': 'Solomon Islands'},
			{'key': 'Somalia', 'value': 'Somalia'},
			{'key': 'South Africa', 'value': 'South Africa'},
			{'key': 'South Georgia', 'value': 'South Georgia'},
			{'key': 'South Sudan', 'value': 'South Sudan'},
			{'key': 'Spain', 'value': 'Spain'},
			{'key': 'Sri Lanka', 'value': 'Sri Lanka'},
			{'key': 'Sudan', 'value': 'Sudan'},
			{'key': 'Suriname', 'value': 'Suriname'},
			{'key': 'Svalbard and Jan Mayen', 'value': 'Svalbard and Jan Mayen'},
			{'key': 'Swaziland', 'value': 'Swaziland'},
			{'key': 'Sweden', 'value': 'Sweden'},
			{'key': 'Switzerland', 'value': 'Switzerland'},
			{'key': 'Syrian Arab Republic', 'value': 'Syrian Arab Republic'},
			{'key': 'Taiwan', 'value': 'Taiwan'},
			{'key': 'Tajikistan', 'value': 'Tajikistan'},
			{'key': 'Tanzania', 'value': 'Tanzania'},
			{'key': 'Thailand', 'value': 'Thailand'},
			{'key': 'Timor-Leste', 'value': 'Timor-Leste'},
			{'key': 'Togo', 'value': 'Togo'},
			{'key': 'Tokelau', 'value': 'Tokelau'},
			{'key': 'Tonga', 'value': 'Tonga'},
			{'key': 'Trinidad and Tobago', 'value': 'Trinidad and Tobago'},
			{'key': 'Tunisia', 'value': 'Tunisia'},
			{'key': 'Turkey', 'value': 'Turkey'},
			{'key': 'Turkmenistan', 'value': 'Turkmenistan'},
			{'key': 'Turks and Caicos Islands', 'value': 'Turks and Caicos Islands'},
			{'key': 'Tuvalu', 'value': 'Tuvalu'},
			{'key': 'Uganda', 'value': 'Uganda'},
			{'key': 'Ukraine', 'value': 'Ukraine'},
			{'key': 'United Arab Emirates', 'value': 'United Arab Emirates'},
			{'key': 'United Kingdom', 'value': 'United Kingdom'},
			{'key': 'United States', 'value': 'United States'},
			{'key': 'United States Minor Outlying Islands', 'value': 'United States Minor Outlying Islands'},
			{'key': 'Uruguay', 'value': 'Uruguay'},
			{'key': 'Uzbekistan', 'value': 'Uzbekistan'},
			{'key': 'Vanuatu', 'value': 'Vanuatu'},
			{'key': 'Venezuela', 'value': 'Venezuela'},
			{'key': 'Vietnam', 'value': 'Vietnam'},
			{'key': 'Virgin Islands', 'value': 'Virgin Islands'},
			{'key': 'Wallis and Futuna', 'value': 'Wallis and Futuna'},
			{'key': 'Western Sahara', 'value': 'Western Sahara'},
			{'key': 'Yemen', 'value': 'Yemen'},
			{'key': 'Zambia', 'value': 'Zambia'},
			{'key': 'Zimbabwe', 'value': 'Zimbabwe'}
		]
	};

	$scope.form_error_message = '';

	var searchParams = $location.search();
	var invalid_parameters =
		!_.get(searchParams, 'invite_code') ||
		!_.get(searchParams, 'email') ||
		!_.get(searchParams, 'user_id');

	if (invalid_parameters === true) {
		//parameters are invalid
		$location.search({});
		$location.path('/login');
	} else {
		$scope.invite_code = decodeURIComponent($location.search().invite_code);
		$scope.email = decodeURIComponent($location.search().email);
		$scope.user_id = decodeURIComponent($location.search().user_id);
		$scope.message = _.get(searchParams, 'message', '');
		$scope.first_name = _.get(searchParams, 'first_name', '');
		$scope.last_name = _.get(searchParams, 'last_name', '');

		$scope.header_message = 'Register your account to get started';
		if ($scope.message == 'primary') {
			$scope.header_message = 'Register your primary user account.';
		}
	}

	if (invalid_parameters !== true) {
		var check_invite_params = {user_id: $scope.user_id, invite_code: $scope.invite_code};
		$http.put('/api.php/check_invite', check_invite_params).then(function (response) {
			if (response.data !== '') {
				$scope.form_error_message = response.data.message;
			}
		}, function () {
			$location.search({});
			$location.path('/login');
		});
	}

	$scope.password = '';
	$scope.password_vrfy_cnfrm = '';
	$scope.country = '';
	$scope.eula_checked = false;

	$scope.microsoft_login = false;
	$http.post('/api.php/check_microsoft_requirement', { username: $scope.email }).then(function(response) {
		if (response.data.status === 'ok' && response.data.data === 'is_required') {
			$scope.microsoft_login = true;
			$scope.header_message = 'Register your account to get started';
		} else {
			$scope.microsoft_login = false;
		}
	}, function() {
		$scope.microsoft_login = true;
	});

	$scope.form_valid = false;
	$scope.validateForm = function () {
		$scope.form_valid = true;
		$scope.form_error_message = '';

		$scope.invalid_fields = {
			first_name: false,
			last_name: false,
			password: false,
			confirm_password: false,
			eula_checked: false
		};

		if (!$scope.microsoft_login) {
			if (!$scope.validatePw()) {
				$scope.invalid_fields.password = true;
				$scope.form_valid = false;
			} else if (!$scope.validatePwMatch()) {
				$scope.invalid_fields.password_confirm = true;
				$scope.form_valid = false;
			}
		}

		if ($scope.first_name === '') {
			$scope.invalid_fields.first_name = true;
			$scope.form_valid = false;
		}

		if ($scope.last_name === '') {
			$scope.invalid_fields.last_name = true;
			$scope.form_valid = false;
		}

		if ($scope.eula_checked !== true) {
			$scope.invalid_fields.eula_checked = true;
			$scope.form_valid = false;
		}

	};

	$scope.validatePw = function () {
		return fdxUtils.isValidPassword($scope.password);
	}

	$scope.validatePwMatch = function () {
		return $scope.password === $scope.password_vrfy_cnfrm;
	}

	$scope.invalid_fields = {
		first_name: false,
		last_name: false,
		password: false,
		confirm_password: false,
		eula_checked: false
	}

	var redirect = ($location.search().redirect !== null && location.search.redirect !== undefined) ? decodeURIComponent($location.search.redirect) : '';
	var searchParams = new URLSearchParams();
	if(redirect !== '') {
		searchParams.set('redirect', redirect);
	}
	searchParams.set('referrer', 'accept_invite');
	searchParams.set('username', $scope.email);

	var application_data = JSON.parse(globals);
	var destination_url = application_data.default_url + '/login?' + searchParams.toString();

	$scope.submit = function () {
		$scope.validateForm();
		if ($scope.form_valid === false) {
			return;
		}

		var params = {
			'user_id': $scope.user_id,
			'password': $scope.password,
			'invite_code': $scope.invite_code,
			'first_name': $scope.first_name,
			'last_name': $scope.last_name,
			'country': $scope.data.country,
			'message': $scope.message
		};

		$http.put('/api.php/accept_invite', params).then(function (response) {
			if (response.data == '') {
				window.location.href = destination_url;
			} else {
				$scope.form_error_message = response.data.message;
			}
		}, function (response) {
			$scope.form_error_message = response.data.message;
		});
	};

	$scope.modal_visible = false;
	$scope.toggleModal = function () {
		$scope.modal_visible = !$scope.modal_visible;
	};

	$scope.password_type = 'password';
	$scope.togglePasswordHidden = function () {
		$scope.password_type = $scope.password_type === 'password' ? 'text' : 'password';
	}

	$scope.confirm_password_type = 'password';
	$scope.togglePasswordConfirmHidden = function () {
		$scope.confirm_password_type = $scope.confirm_password_type === 'password' ? 'text' : 'password';
	}

	$scope.password_button_class = function (type) {
		return type === 'password' ? 'fas fa-eye' : 'fas fa-eye-slash';
	}
}]); 