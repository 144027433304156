export default class FdxPasswordResetFormController {
    // Services
    AuthDataService = null;
    AuthStateService = null;
    fdxUtils = null;

    // Local Attributes
    loading = false;
    submissionError = false;
    token = null;
    rememberThisComputer = false;

    constructor(AuthDataService, AuthStateService, fdxUtils) {
        this.AuthDataService = AuthDataService;
        this.AuthStateService = AuthStateService;
        this.fdxUtils = fdxUtils;
    }

    validatePassword(password) {
      return this.fdxUtils.isValidPassword(password);
    }

    submit() {
        this.loading = true;

		const promise = this.AuthDataService.updatePasswordWithToken(
            this.AuthStateService.getTwoFactorTokens(),
            this.password
        );

        promise.then(
            () => {
                this.AuthStateService.redirectUser();
            },
            (response) => {
                this.submissionError = response.message;
            }
        ).finally(() => {
            this.loading = false;
        });
    }
}

FdxPasswordResetFormController.$inject = ['AuthDataService', 'AuthStateService', 'fdxUtils'];