feedAuthApp.controller('ResetPasswordController', ['$scope', '$http', '$location', function($scope, $http, $location) {
	var searchParams = $location.search();
	$scope.invalid_parameters =
		!_.get(searchParams, 'authentication_code') ||
		!_.get(searchParams, 'email') ||
		!_.get(searchParams, 'user_id');

	if ($scope.invalid_parameters === true) {
		$location.path('/forgot_password');
	} else {
		$scope.authentication_code = decodeURIComponent(searchParams.authentication_code);
		$scope.email = decodeURIComponent(searchParams.email);
		$scope.user_id = decodeURIComponent(searchParams.user_id);
	}

	if (_.get(searchParams, 'redirect')) {
		$scope.redirect = decodeURIComponent(searchParams.redirect);
	}

	$scope.password = '';
	$scope.password_vrfy_cnfrm = '';

	var password_regex = new RegExp(/^(?=.*\d)(?=.*[a-z])(?!.* )(?=.*[^a-zA-Z0-9]).{8,}$/m);
	$scope.validatePassword = function(password) {
		$scope.password_valid = password_regex.test(password);
	};

	$scope.validatePwMatch = function () {
		$scope.passwords_match = false;
		$scope.passwords_match_error = false;

		if ($scope.password !== $scope.password_vrfy_cnfrm) {
			$scope.passwords_match_error = 'Passwords must match';
			return;
		} else {
			$scope.passwords_match = true;
		}
	}

	$scope.submit = function() {
		if ($scope.password_valid !== true || $scope.passwords_match !== true) {
			return;
		}

		$scope.passwords_match_error = false;

		var params = {
			'user_id' : $scope.user_id,
			'password': $scope.password,
			'authentication_code': $scope.authentication_code
		};

		$http.put('/api.php/reset_password', params).then(function(response) {
			if (response.data.status === 'ok') {
				$location.search({});
				$location.path('/login').search({referrer: 'reset_password'});
			} else {
				$scope.passwords_match_error = response.data.message;
			}
		}, function(response) {
            if (response.data != null && typeof(response.data.message) !== 'undefined') {
                $scope.passwords_match_error = response.data.message
            } else {
                $scope.passwords_match_error = 'Internal Error';
            }
			
		});
	};
}]);