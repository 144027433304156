feedAuthApp.controller('RedirectController', ['$scope', '$cookies', '$http', '$location', '$q', 'AuthStateService', function ($scope, $cookies, $http, $location, $q, AuthStateService) {
    $scope.tokens = AuthStateService.getTwoFactorTokens();
    if (!$scope.tokens.length) {
        try {
            $scope.tokens = JSON.parse($cookies.get('tokens'));

            $cookies.remove('tokens', {
                'path': '/'
            });
        } catch (e) {
            $scope.tokens = [];
        }
    }

    if (!$scope.tokens.length) {
        $location.path('/login').search($location.search());
        return;
    }

    var application_data = JSON.parse(globals);
    $scope.applications = application_data.apps;

    $scope.app = '';
    if ($location.search().app !== null && $location.search().app !== undefined) {
        $scope.app = decodeURIComponent($location.search().app);
    }

    if ($scope.app === '') {
        $scope.app = application_data.default_app;
    }
    var redirect = '';
    if ($location.search().redirect !== null && $location.search().redirect !== undefined) {
        redirect = decodeURIComponent($location.search().redirect);
    }

    var valid_app = false;
    var destination_base_url = application_data.default_url;

    $scope.applications.forEach(function (application) {
        if ($scope.app === application.name) {
            valid_app = true;
            destination_base_url = application.base_url;
            if (redirect === '') {
                redirect = application.default_redirect;
            }
        }

        $scope.tokens.forEach((token) => {
            if (token['app'] === application.name) {
                application.token = token.token;
            }
        });
    });

    if (valid_app === false) {
        //clear app param, otherwise gets carried back to login, but maintain rest of params
        $location.search('app', null);
        $location.path('/login');
    }

    // We did this on login route for a reason, so doing here too
    if (typeof redirect === 'string') {
        while (redirect.substring(0, 1) == '/') {
            redirect = redirect.substring(1);
        }
    }

    var destination_url = destination_base_url + '/' + (redirect || '');

    var searchParams = new URLSearchParams(),
        urlParams = $location.search();

    Object.keys(urlParams).forEach(function (key) {
        searchParams.set(key, urlParams[key]);
    });

    const promises = $scope.applications.map(
        (application) => {
            return $http
                .post(
                    application.login_url,
                    {
                        'token': application.token
                    },
                    {
                        withCredentials: true
                    }
                )
                .then(
                    (response) => {
                        return {
                            success: response.status === 200,
                            application
                        };
                    }
                )
                .catch(
                    (e) => {
                        console.warn(e);
                        console.warn(
                            '[RedirectController] Failed to login to application:',
                            application
                        );

                        return {
                            success: false,
                            application
                        };;
                    }
                );
        }
    );

    $q
        .all(promises)
        .then(
            (results) => {
                for (const result of results) {
                    if (
                        result.success === true &&
                        result.application.name === $scope.app
                    ) {
                        window.location.href = destination_url;
                        return;
                    }
                }

                searchParams.delete('tokens');
                window.location.href = `/login?${searchParams.toString()}`;
            }
        );
}]);
