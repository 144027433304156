feedAuthApp =
    angular
        .module('feedAuthApp', ['ui.select', 'angularUtils.directives.dirPagination', 'ngRoute', 'ngCookies'])
        .config(['$routeProvider', '$compileProvider', '$httpProvider', '$locationProvider', function ($routeProvider, $compileProvider, $httpProvider, $locationProvider) {
            $locationProvider.html5Mode(true);
            $compileProvider.debugInfoEnabled(false);

            const applicationData = JSON.parse(globals);
            const { apps } = applicationData;

            for (const app of apps) {
                for (const host of app.xsrf_hosts) {
                    $httpProvider.xsrfTrustedOrigins.push(host);
                }
            }

            $routeProvider
                //login page
                .when('/login', {
                    template: require('./login/login.html'),
                    controller: 'LoginController'
                })

                // forgot password
                .when('/forgot_password', {
                    template: require('./forgot_password/forgot_password.html'),
                    controller: 'ForgotPasswordController'
                })

                //accept invite
                .when('/accept_invite', {
                    template: require('./accept_invite/accept_invite.html'),
                    controller: 'AcceptInviteController'
                })

                //reset password
                .when('/reset_password', {
                    template: require('./reset_password/reset_password.html'),
                    controller: 'ResetPasswordController'
                })

                //redirect
                .when('/redirect', {
                    template: require('./redirect/redirect.html'),
                    controller: 'RedirectController'
                })

                //logout
                .when('/logout', {
                    template: require('./logout/logout.html'),
                    controller: 'LogoutController'
                })

                .otherwise({
                    redirectTo: '/login'
                });
        }])
        .controller('MainController', ['$scope', '$rootScope', '$http', '$q', MainController])
        .directive('loadingSpinner', [LoadingSpinnerDirective])
        .directive('focusInput', [FocusInputDirective])
        .directive('imageLoaded', [ImageLoadedDirective]);

function MainController($scope, $rootScope, $http, $q) {

}

function LoadingSpinnerDirective() {
    return {
        restrict: 'E',
        template: function () {
            return '<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>';
        }
    };
}

function FocusInputDirective() {
    return {
        restrict: 'A',
        link: function ($scope, elm) {
            elm[0].focus();
        }
    };
}

function ImageLoadedDirective() {
    return {
        restrict: 'A',
        scope: {
            callback: '='
        },
        link: function ($scope, elm) {
            elm.on('load', function (e, h) {
                $scope.callback('success', angular.element(e.target).attr('data-application-name'));
            });

            elm.on('error', function (jqXHR, error, errorThrown) {
                $scope.callback('error', angular.element(jqXHR.target).attr('data-application-name'));
            });
        }
    };
}
