export default class AuthDataService {
    // Services
    $http = null;
    $q = null;

    constructor($http, $q) {
        this.$http = $http;
        this.$q = $q;
    }

    login(username, password, remember_me_token) {
        const def = this.$q.defer();

        const promise = this.$http.post('/api.php/login', {
            'username': username,
            'password': password,
            'remember_me_token': remember_me_token
        });

        promise.then(
            (response) => {
                def.resolve(response.data);
            },
            (response) => {
                def.reject(response.data);
            }
        );

        return def.promise;
    }

    loginWithToken(username, password, token, remember_this_computer) {
        const def = this.$q.defer();

        const promise = this.$http.post('/api.php/login_with_token', {
            'username': username,
            'password': password,
            'token': token,
            'remember_this_computer': remember_this_computer
        });

        promise.then(
            (response) => {
                def.resolve(response.data);
            },
            (response) => {
                def.reject(response.data);
            }
        );

        return def.promise;
    }

    updatePasswordWithToken(jwt_tokens, new_password) {
        const def = this.$q.defer();

        let token = jwt_tokens.filter((token) => {
            return token.app === 'feedonomics';
        });

        const promise = this.$http.post('/api.php/update_password_with_token', {
            'token': token[0]?.token,
            'password': new_password
        });

        promise.then(
            (response) => {
                def.resolve(response.data);
            },
            (response) => {
                def.reject(response.data);
            }
        );

        return def.promise;
    }
}

AuthDataService.$inject = ['$http', '$q'];