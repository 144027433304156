feedAuthApp.controller('LoginController', ['$scope', '$routeParams', 'AuthStateService', function($scope, $routeParams, AuthStateService) {
	$scope.isCurrentView = (view) => {
		return AuthStateService.getView() === view;
	};

	if ($routeParams['app']) {
		AuthStateService.setApp(decodeURIComponent($routeParams['app']));
	}
	
	if ($routeParams['legacy_feedsupport_redirect']) {
		const legacyFeedsupportRedirectValue = decodeURIComponent($routeParams['legacy_feedsupport_redirect']);
		if (legacyFeedsupportRedirectValue === '1') {
			AuthStateService.setLegacyFeedsupportRedirect(true);
		}
	}

	if ($routeParams['redirect']) {
		AuthStateService.setRedirect(decodeURIComponent($routeParams['redirect']));
	}

	if ($routeParams['referrer']) {
		AuthStateService.setReferrer($routeParams['referrer']);
	}

	if ($routeParams['session']) {
		AuthStateService.setSession($routeParams['session']);
	}
}]);