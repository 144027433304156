feedAuthApp.controller('LogoutController', ['$scope', '$http', '$location', '$q', function ($scope, $http, $location, $q) {
    var application_data = JSON.parse(globals);
    $scope.applications = application_data.apps;

    var searchParams = new URLSearchParams(),
        urlParams = $location.search();

    Object.keys(urlParams).forEach(function (key) {
        searchParams.set(key, urlParams[key]);
    });

    const promises = $scope.applications.map(
        (application) => {
            return $http
                .post(
                    application.logout_url,
                    null,
                    {
                        withCredentials: true
                    }
                )
                .then(
                    (response) => {
                        return {
                            success: response.status === 200,
                            application
                        };
                    }
                )
                .catch(
                    (e) => {
                        console.warn(e);
                        console.warn(
                            '[LogoutController] Failed to logout of application:',
                            application
                        );

                        return {
                            success: false,
                            application
                        };;
                    }
                );
        }
    );

    $q
        .all(promises)
        .then(
            () => {
                window.location.href = '/login?' + searchParams.toString();
            }
        );
}]);
