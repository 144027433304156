export default class fdxUtils {
  
    isValidEmail(email) {
      const regExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return regExp.test(String(email).toLowerCase());
    }
  
    isValidPassword(password) {
      const regExp = /^(?=.*\d)(?=.*[a-z])(?!.* )(?=.*[^a-zA-Z0-9]).{8,}$/m;
      return regExp.test(password);
    }

  }
  
  fdxUtils.$inject = [];