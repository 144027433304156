feedAuthApp.controller('ForgotPasswordController', ['$scope', '$http', '$location', function($scope, $http, $location) {
	$scope.header_text = "Forgot Password";
	$scope.description_text = "Please enter a valid email address associated with your account.";
	$scope.button_text = "Request Password Reset";
	$scope.success_text = "An email containing instructions to reset your password has been sent to your associated email address if your email address exists.";
	$scope.valid_email = false;

	$scope.legacy_feedsupport_redirect = false;
	if ($location.search().legacy_feedsupport_redirect != null) {
		$scope.legacy_feedsupport_redirect_value = decodeURIComponent($location.search().legacy_feedsupport_redirect);
		if ($scope.legacy_feedsupport_redirect_value === '1') {
			$scope.legacy_feedsupport_redirect = true;
		}
	}

	if ($scope.legacy_feedsupport_redirect === true) {
		$scope.header_text = "Confirm Legacy Feed Migration";
		$scope.description_text = "Please enter the same email address that you used for your FeedSupport user account to complete migration into Feedonomics";
		$scope.button_text = "Request Confirmation";
		$scope.success_text = "Migration Email Sent.";
	}

	var email_validation_regex = new RegExp(/^.+@.+\..+$/);
	$scope.validateEmail = function(email) {
		$scope.valid_email = email_validation_regex.test(email);
	}

	$scope.email_sent = false;
	$scope.submitEmail = function(email) {
		$scope.email_error = '';

		var params = {
			email: email
		};

		// TO DO add feedsupport_redirect:1 to params if legacy feedsupport redirect
		$http.put('/api.php/forgot_password', params).then(function(response) {
			if (response.data.status == 'ok') {
				$scope.email_sent = true;
			} else {
				$scope.email_error = 'There was a problem, please try again later';
			}
		}, function() {
			$scope.email_error = 'There was a problem, please try again later';
		});
	};
}]);