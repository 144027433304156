export default class FdxLoginFormController {
    // Services
    AuthDataService = null;
    AuthStateService = null;
    $location = null;
    $http = null;
    $window = null;
    fdxUtils = null;

    // Local Attributes
    legacyFeedsupportRedirect = null;
    loading = false;
    password = null;
    session = null;
    submissionError = null;
    successMessage = null;
    username = null;
    traditional_login = true;

    microsoft_login = false;
    sso_login_error = '';
    
    okta_login = false;

    sso_login_url = '';

    constructor(AuthDataService, AuthStateService, $location, $http, $window, fdxUtils) {
        this.AuthDataService = AuthDataService;
        this.AuthStateService = AuthStateService;
        this.$location = $location;
        this.$http = $http;
        this.$window = $window;
        this.fdxUtils = fdxUtils;
    }

    get validEmail() {
        return this.fdxUtils.isValidEmail(this.username);
    }

    $onInit() {
        this.legacyFeedsupportRedirect = this.AuthStateService.getLegacyFeedsupportRedirect();
        this.session = this.AuthStateService.getSession();

        const referrer = this.AuthStateService.getReferrer();

        switch (referrer) {
            case 'accept_invite':
                this.successMessage = 'Account successfully created! Check your email for tips on how to get started.';
                break;
            case 'reset_password':
                this.successMessage = 'Password reset successfully!';
                break;
        }

        if (this.$location.search().sso_login_error !== null && this.$location.search().sso_login_error !== undefined) {
            this.sso_login_error = decodeURIComponent(this.$location.search().sso_login_error);
        }

        // initial check on load for password manager auto-population
        if (this.$location.search().username !== null && this.$location.search().username !== undefined) {
            this.username = decodeURIComponent(this.$location.search().username);
            this.checkMicrosoftRequirement();
        }
    }

    submit() {
        this.loading = true;
        this.submissionError = null;

		const promise = this.AuthDataService.login(
            this.username, 
            this.password,
            this.AuthStateService.getRememberMeToken()
        );

        promise.then(
            (response) => {
                if (response.data.tokens) {
                    this.AuthStateService.setTwoFactorTokens(response.data.tokens);
                    
                    if (response.data.password_needs_reset) {
                        this.AuthStateService.setCredentials(this.username, this.password);
                        this.AuthStateService.setView('reset');
                    } else {
                        this.AuthStateService.redirectUser();
                    }
                } else {
                    this.AuthStateService.setCredentials(this.username, this.password);
                    this.AuthStateService.setView('2fa');
				}
            },
            (response) => {
                if (response.message === 'Invalid Authentication') {
                    this.submissionError = 'Invalid username / password combination';
                } else {
                    this.submissionError = response.message;
                }
            }
        ).finally(() => {
            this.loading = false;
        });
    }

    goToSsoLogin = () => {
        const params = {
            username: this.username,
            app: this.$location.search().app,
            redirect: this.$location.search().redirect
        };
        // since the URLs creation sets a cookie, we need to set it again right before the 
        // redirect to ensure multiple logged out tabs all login successfully to their redirect requests
        this.$http.post('/api.php/check_sso_requirement', params).then((response) => {
            if (response.data.status === 'ok' && response.data.data.is_required) {
                this.$window.location.href = response.data.data.url;
            }
        }, angular.noop);
    }

    checkMicrosoftRequirement = () => {

        if(this.username && this.validEmail) {

            const params = {
                username: this.username,
                app: this.$location.search().app,
                redirect: this.$location.search().redirect
            };
            this.$http.post('/api.php/check_sso_requirement', params).then((response) => {
                if (response.data.status === 'ok' && response.data.data.is_required) {
                    this.sso_login_url = response.data.data.url;
                    this.traditional_login = false;
                    this.microsoft_login = false;
                    this.okta_login = false;
                    switch (response.data.data.sso_type) {
                        case 'OKTA':
                            this.okta_login = true;
                            break;
                        case 'AZURE':
                            this.microsoft_login = true;
                            break;
                        default:
                            console.log('Unknown SSO type' + response.data.data.sso_type);
                            break;
                    }
                } else {
                    this.traditional_login = true;
                    this.microsoft_login = false;
                    this.okta_login = false;
                }
            }, angular.noop);
        }
        // default settings
        this.traditional_login = true;
        this.microsoft_login = false;
        this.okta_login = false;
    }
}

FdxLoginFormController.$inject = ['AuthDataService', 'AuthStateService', '$location', '$http', '$window', 'fdxUtils'];